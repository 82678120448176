import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import { useState } from 'react';

export default function InputField({
  label,
  name,
  placeholder,
  type,
  register,
  errors,
  message,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  value,
  isValid,
  customError,
  defaultValue,
  disabled,
  min,
  max,
  step,
  onInput,
  autoComplete,
  customWidth,
  overrideClass,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const inputProps = {
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    value,
    defaultValue,
    disabled,
    min,
    max,
    step,
    onInput,
    autoComplete,
    name,
    overrideClass,
  };

  Object.keys(inputProps).forEach((key) => inputProps[key] === undefined && delete inputProps[key]);

  const fieldErrors = (errors && errors[name]) || customError;

  const inputType = type === 'password' && showPassword ? 'text' : type;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={classNames(
          'absolute left-3 top-[-0.6rem] inline-block bg-white px-1 font-primary text-xs font-light tracking-wide z-10',
          fieldErrors
            ? 'text-functional-error'
            : isValid
              ? 'focus-within:text-green-500'
              : 'focus-within:text-accent',
        )}
      >
        {label}
      </label>
      <div className="relative flex items-center">
        <input
          type={inputType}
          id={name}
          {...(register ? register(name) : {})}
          className={classNames(
            'block w-full rounded-md px-4 py-5 font-secondary text-sm tracking-wide text-primary-dark shadow-sm outline-none ring-1 ring-inset ring-[#E1E1E1] placeholder:text-primary-dark/30 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
            fieldErrors ? 'ring-red-500' : isValid ? 'ring-green-500' : 'focus:ring-accent',
            disabled ? 'bg-gray-100/20' : '',
            customWidth ? customWidth : '',
            overrideClass,
          )}
          placeholder={placeholder}
          {...inputProps}
        />
        {type === 'password' && (
          <div
            className="absolute right-0 flex cursor-pointer items-center pr-3"
            onClick={() => setShowPassword(!showPassword)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            <Icon
              iconName={showPassword ? 'eye-slash' : 'eye'}
              color="text-gray-400"
              overrideClass="w-4 h-4"
            />
          </div>
        )}
        {isValid && !fieldErrors && type !== 'number' && type !== 'date' && type !== 'password' && (
          <div className={classNames('absolute right-0 flex items-center pr-3 text-sm')}>
            <Icon iconName="check" color="text-green-500" />
          </div>
        )}
      </div>
      {fieldErrors && (
        <div className="mt-2 font-primary text-sm font-light tracking-wide text-functional-error">
          {message}
        </div>
      )}
    </div>
  );
}
