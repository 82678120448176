import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import Icon from './Icon';

export default function Modal({
  showModal,
  setShowModal,
  modalTitle,
  modalContent,
  onClose = null,
  modalWidth,
  showCloseButton = false,
}) {
  const width = {
    md: 'max-w-xl',
    lg: 'max-w-2xl',
    xl: 'max-w-3xl',
  };

  const validatedModalWidth = width[modalWidth] || width['md'];

  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={!onClose ? () => setShowModal(false) : onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  validatedModalWidth,
                  'relative w-full rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all',
                )}
              >
                {showCloseButton && (
                  <button
                    type="button"
                    className="absolute right-6 top-7 text-primary-dark/80 focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <Icon iconName="x" size="md" className="size-6" />
                  </button>
                )}
                <Dialog.Title as="h3" className="pb-2 font-secondary text-sm font-bold">
                  {modalTitle}
                </Dialog.Title>
                {modalContent}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
