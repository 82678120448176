import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import { baseStyle, buttonStyle, iconStyle } from './buttonConstants';

const entityChecker = (a, b) => a !== '' && b !== '';

const Button = ({
  buttonType = 'primary',
  label = '',
  disabled = false,
  type = 'button',
  onClick = () => {},
  icon = '',
  iconPosition = 'right',
  iconSize,
  overrideClass = '',
  hoverText = '',
  loading = false,
}) => {
  const hasIcon = entityChecker(icon, iconPosition);

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        baseStyle,
        buttonStyle[buttonType],
        !!hasIcon && iconStyle[iconPosition],
        overrideClass,
        loading && 'cursor-not-allowed opacity-75',
      )}
      title={hoverText}
    >
      {loading ? (
        <Icon iconName="spinner" size={iconSize || 'md'} overrideClass="animate-spin py-[2.5px]" />
      ) : (
        <>
          {label}
          {!!hasIcon && <Icon size={iconSize} iconName={icon} />}
        </>
      )}
    </button>
  );
};

export default Button;
